import React, {useEffect, useRef, useState} from "react";

export default function ModelParameterSpace({data}) {
  const canvasRef = useRef(null);
  const [normalizedPositions, setNormalizedPositions] = useState({
    gray: null, red: null, orange: null
  });
  const [currentPosition, setCurrentPosition] = useState(null);
  const [originalGrayPosition, setOriginalGrayPosition] = useState(null);
  const [showArrow, setShowArrow] = useState(false);
  const [isDataNull, setIsDataNull] = useState(false);

  const initializePositions = () => {
    if (data) {
      const initialPositions = {
        gray: {x: data[0][0], y: data[0][1]},
        orange: {x: data[1][0], y: data[1][1]},
        red: {x: data[2][0], y: data[2][1]}
      };

      const rangeBoxMargin = 0.35;
      const rangeBox = {
        minX: 1.5 + rangeBoxMargin, maxX: 5.5 - rangeBoxMargin, minY: 1.5 + rangeBoxMargin, maxY: 4.5 - rangeBoxMargin
      };

      const minPos = {
        x: Math.min(initialPositions.gray.x, initialPositions.red.x, initialPositions.orange.x),
        y: Math.min(initialPositions.gray.y, initialPositions.red.y, initialPositions.orange.y)
      };

      const maxPos = {
        x: Math.max(initialPositions.gray.x, initialPositions.red.x, initialPositions.orange.x),
        y: Math.max(initialPositions.gray.y, initialPositions.red.y, initialPositions.orange.y)
      };

      const normalizePosition = (position) => {
        return {
          x: maxPos.x === minPos.x ? (rangeBox.maxX + rangeBox.minX) / 2 : ((position.x - minPos.x) / (maxPos.x - minPos.x)) * (rangeBox.maxX - rangeBox.minX) + rangeBox.minX,
          y: maxPos.y === minPos.y ? (rangeBox.maxY + rangeBox.minY) / 2 : ((position.y - minPos.y) / (maxPos.y - minPos.y)) * (rangeBox.maxY - rangeBox.minY) + rangeBox.minY
        };
      };

      const gray = normalizePosition(initialPositions.gray);
      const red = normalizePosition(initialPositions.red);
      const orange = normalizePosition(initialPositions.orange);

      setNormalizedPositions({gray, red, orange});
      setCurrentPosition(gray);
      setOriginalGrayPosition(gray); // 원래 회색 점 위치 저장
      setShowArrow(false); // 화살표 숨기기
      setIsDataNull(false); // 데이터가 null이 아님을 설정

      setTimeout(() => {
        animateMovement(gray, orange);
      }, 1000);
    } else {
      setIsDataNull(true); // 데이터가 null임을 설정
      setShowArrow(false); // 화살표 숨기기
      setCurrentPosition(null); // 점을 숨기기 위해 null로 설정
    }
  };

  const animateMovement = (startPos, endPos) => {
    const duration = 1500; // 애니메이션 지속 시간 (밀리초)
    const startTime = performance.now();

    const step = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      const newX = startPos.x + (endPos.x - startPos.x) * progress;
      const newY = startPos.y + (endPos.y - startPos.y) * progress;

      setCurrentPosition({x: newX, y: newY});

      if (progress < 1) {
        requestAnimationFrame(step);
      } else {
        setShowArrow(true); // 이동이 완료되면 화살표를 표시
      }
    };

    requestAnimationFrame(step);
  };

  useEffect(() => {
    initializePositions();
  }, [data]);

  const calculateArrowDirection = (gray, orange) => {
    const dx = orange.x - gray.x;
    const dy = orange.y - gray.y;
    const angle = Math.atan2(dy, dx);
    return angle;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const scale = window.devicePixelRatio;
    canvas.width = canvas.offsetWidth * scale;
    canvas.height = canvas.offsetHeight * scale;
    ctx.scale(scale, scale);

    const drawScene = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw grid
      ctx.strokeStyle = "#c8c8c8";
      ctx.lineWidth = 1;
      for (let i = 1; i <= 5; i++) {
        ctx.moveTo(i * 50, 0);
        ctx.lineTo(i * 50, 250);
      }
      for (let j = 1; j <= 4; j++) {
        ctx.moveTo(0, j * 50);
        ctx.lineTo(300, j * 50);
      }
      ctx.stroke();

      if (isDataNull || !currentPosition || !normalizedPositions.red) {
        return;
      }

      const gray = currentPosition;
      const red = normalizedPositions.red;
      const orange = normalizedPositions.orange;

      const dotRadius = 10;
      const arrowLength = 40; // 화살표의 고정된 길이

      // Draw gray or orange dot depending on movement state
      ctx.beginPath();
      ctx.arc((gray.x - 1) * 50 + 25, (gray.y - 1) * 50 + 25, dotRadius, 0, 2 * Math.PI);
      ctx.fillStyle = gray.x === orange.x && gray.y === orange.y ? "#4a5759" : "#b0c4b1";
      ctx.fill();
      ctx.strokeStyle = "black";
      ctx.lineWidth = 2;
      // if (gray.x === orange.x && gray.y === orange.y) {
      //   ctx.setLineDash([5, 5]); // 점선 보더
      // }
      ctx.stroke();
      ctx.setLineDash([]); // 점선 보더 설정 해제
      ctx.closePath();

      // Draw red dot
      ctx.beginPath();
      ctx.arc((red.x - 1) * 50 + 25, (red.y - 1) * 50 + 25, dotRadius, 0, 2 * Math.PI);
      ctx.fillStyle = "#E18683";
      ctx.fill();
      ctx.strokeStyle = "black";
      ctx.lineWidth = 2;
      ctx.stroke();
      ctx.closePath();

      ctx.font = "18px Arial";
      ctx.fillStyle = "black";

      ctx.fillText("Refer", (red.x - 1) * 50 + 3, (red.y - (red.y > orange.y ? 0 : 1)) * 50 + 10);

      // Draw arrow only after movement
      // if (showArrow && originalGrayPosition) {
      //   const angle = calculateArrowDirection(originalGrayPosition, orange); // 원래 회색 점과 주황색 점 사이의 각도 계산
      //   const centerX = ((originalGrayPosition.x - 1) * 50 + 25 + (orange.x - 1) * 50 + 25) / 2;
      //   const centerY = ((originalGrayPosition.y - 1) * 50 + 25 + (orange.y - 1) * 50 + 25) / 2;
      //
      //   const startX = centerX - (arrowLength / 2) * Math.cos(angle);
      //   const startY = centerY - (arrowLength / 2) * Math.sin(angle);
      //   const endX = centerX + (arrowLength / 2) * Math.cos(angle);
      //   const endY = centerY + (arrowLength / 2) * Math.sin(angle);
      //
      //   ctx.beginPath();
      //   ctx.moveTo(startX, startY);
      //   ctx.lineTo(endX, endY);
      //   ctx.stroke();
      //
      //   // Draw arrowhead
      //   const arrowHeadLength = 10;
      //   ctx.beginPath();
      //   ctx.moveTo(endX, endY);
      //   ctx.lineTo(endX - arrowHeadLength * Math.cos(angle - Math.PI / 6), endY - arrowHeadLength * Math.sin(angle - Math.PI / 6));
      //   ctx.lineTo(endX - arrowHeadLength * Math.cos(angle + Math.PI / 6), endY - arrowHeadLength * Math.sin(angle + Math.PI / 6));
      //   ctx.lineTo(endX, endY);
      //   ctx.fillStyle = "black";
      //   ctx.fill();
      //   ctx.closePath();
      // }
    };

    drawScene();
  }, [currentPosition, normalizedPositions, showArrow, isDataNull]);

  return (<article>
    <h2>Model Parameter Space</h2>
    <canvas ref={canvasRef} style={{width: "300px", height: "250px", border: "none"}}/>
  </article>);
}

import React from "react";
import "./graph.css";

export default function Graph({value, divisions, afterValue}) {
  return (<div className="graphContainer">
    <div className="graphBox">
      {value !== undefined && (afterValue !== undefined ? (<div className="doubleGraph">
        <div className="graph" style={{width: `${afterValue}%`, backgroundColor: "#4a5759"}}></div>
        <div className="graph" style={{width: `${value}%`, backgroundColor: "#b0c4b1"}}></div>
      </div>) : (<div className="graph" style={{width: `${value}%`, backgroundColor: "#b0c4b1"}}></div>))}
      {/*{divisions.map((division, index) => (<div*/}
      {/*  key={index}*/}
      {/*  className="divisionLine"*/}
      {/*  style={{left: `${division}%`}}*/}
      {/*></div>))}*/}
      <div className="divisionBox"
        style={{left: `${divisions[0]}%`, backgroundColor: "#fefae0", width: `${divisions[1] - divisions[0]}%`}}></div>
      <div className="divisionBox"
        style={{
          left: `${divisions[1]}%`, backgroundColor: "#e9edc9", width: `${100 - divisions[1]}%`
        }}></div>
    </div>
  </div>);
}

import {read} from "mat-for-js";

export default function processMATFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      if (arrayBuffer instanceof ArrayBuffer) {
        try {
          const matFile = read(arrayBuffer);
          const {processedData, keys} = processMatData(matFile);
          const modifiedData = modifyData(processedData);
          const name = extractName(keys[0]); // 이름 추출

          resolve({modifiedData, name}); // 데이터와 이름을 함께 반환
        } catch (error) {
          console.error("Error reading MAT file:", error);
          reject(error);
        }
      } else {
        console.error("Error: The result is not an ArrayBuffer.");
        reject(new Error("Not an ArrayBuffer"));
      }
    };

    reader.onerror = (e) => {
      console.error("File reading error:", e);
      reject(e);
    };

    reader.readAsArrayBuffer(file);
  });
}

const processMatData = (data) => {
  const keys = Object.keys(data.data).filter(key => !key.startsWith("__"));
  if (keys.length === 0) {
    console.error("No valid data keys found in the MAT file");
    return {processedData: null, keys: []};
  }

  const categories = keys.map(key => data.data[key]);
  const numRows = categories[0].length;
  const result = [];

  for (let i = 0; i < numRows; i++) {
    const row = [];
    for (let j = 0; j < categories.length; j++) {
      row.push(categories[j][i]);
    }
    result.push(row);
  }

  return {processedData: result, keys};
};

const modifyData = (processedData) => {
  if (!processedData) {
    return null;
  }

  return processedData[0][0].map((_, colIndex) => processedData.map(row => row[0][colIndex].flat(Infinity)));
};

// 이름 추출 함수
const extractName = (key) => {
  const parts = key.split("_");
  return parts[parts.length - 1]; // 이름이 마지막 부분에 있다고 가정
};

import React, {useEffect, useRef, useState} from "react";

export default function TaskEnvironmentSpace({data}) {
  const canvasRef = useRef(null);
  const [previousData, setPreviousData] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isOpacityChanging, setIsOpacityChanging] = useState(false);

  const delayBetweenDots = 500; // 각 공이 나타나는 간격 (밀리초), 변수로 지정 가능

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const scale = window.devicePixelRatio;
    canvas.width = canvas.offsetWidth * scale;
    canvas.height = canvas.offsetHeight * scale;
    ctx.scale(scale, scale);

    const clearCanvas = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };

    const drawDotsAndArrows = (positions, numVisibleDots, opacity = 1) => {
      clearCanvas();
      const dotRadius = 4;
      const arrowOffset = 3;

      positions.slice(0, numVisibleDots).forEach((pos, index) => {
        // Draw the dots
        ctx.beginPath();
        ctx.arc(pos.x, pos.y, dotRadius, 0, 2 * Math.PI);
        ctx.fillStyle = `rgb(176, 196, 177)`;
        ctx.fill();
        ctx.strokeStyle = `rgba(0, 0, 0, ${opacity})`;
        ctx.lineWidth = 0.5;
        ctx.stroke();
        ctx.closePath();

        if (index > 0) {
          const prevPos = positions[index - 1];
          const dx = pos.x - prevPos.x;
          const dy = pos.y - prevPos.y;
          const angle = Math.atan2(dy, dx);

          // Calculate arrow positions
          const startX = prevPos.x + (dotRadius + arrowOffset) * Math.cos(angle);
          const startY = prevPos.y + (dotRadius + arrowOffset) * Math.sin(angle);
          const endX = pos.x - (dotRadius + arrowOffset) * Math.cos(angle);
          const endY = pos.y - (dotRadius + arrowOffset) * Math.sin(angle);

          // Draw the arrow
          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(endX, endY);
          ctx.strokeStyle = `rgba(200, 200, 200, ${opacity})`;
          ctx.lineWidth = 1;
          ctx.stroke();

          // Draw arrowhead
          const arrowHeadLength = 8;
          ctx.beginPath();
          ctx.moveTo(endX, endY);
          ctx.lineTo(endX - arrowHeadLength * Math.cos(angle - Math.PI / 6), endY - arrowHeadLength * Math.sin(angle - Math.PI / 6));
          ctx.lineTo(endX - arrowHeadLength * Math.cos(angle + Math.PI / 6), endY - arrowHeadLength * Math.sin(angle + Math.PI / 6));
          ctx.lineTo(endX, endY);
          ctx.fillStyle = `rgba(200, 200, 200, ${opacity})`;
          ctx.fill();
          ctx.closePath();
        }
      });
    };

    if (!data || data.length === 0) {
      if (previousData) {
        setIsOpacityChanging(true);
        let start = null;

        const fadeOut = (timestamp) => {
          if (!start) {
            start = timestamp;
          }
          const progress = Math.min((timestamp - start) / 500, 1);
          const opacity = 1 - progress;

          drawDotsAndArrows(previousData, previousData.length, opacity);

          if (progress < 1) {
            requestAnimationFrame(fadeOut);
          } else {
            clearCanvas();
            setPreviousData(null);
            setIsOpacityChanging(false);
          }
        };

        requestAnimationFrame(fadeOut);
      }
      return;
    }

    const numDots = data.length;
    const minValue = Math.min(...data);
    const maxValue = Math.max(...data);

    const padding = 20;

    const positions = data.map((value, index) => ({
      x: padding + (index / (numDots - 1)) * (canvas.width / scale - 2 * padding),
      y: canvas.height / scale - (padding + ((value - minValue) / (maxValue - minValue)) * (canvas.height / scale - 2 * padding))
    }));

    if (!previousData) {
      setIsOpacityChanging(true);
      let start = null;
      let visibleDots = 0;

      const fadeInDots = (timestamp) => {
        if (!start) {
          start = timestamp;
        }
        const elapsed = timestamp - start;

        // Incrementally reveal dots based on elapsed time
        visibleDots = Math.min(Math.floor(elapsed / delayBetweenDots) + 1, positions.length);

        drawDotsAndArrows(positions, visibleDots);

        if (visibleDots < positions.length) {
          requestAnimationFrame(fadeInDots);
        } else {
          setPreviousData(positions);
          setIsOpacityChanging(false);
        }
      };

      requestAnimationFrame(fadeInDots);
      return;
    }

    if (data !== previousData && !isOpacityChanging) {
      setIsTransitioning(true);
      let start = null;
      let visibleDots = 0;

      const animateDots = (timestamp) => {
        if (!start) {
          start = timestamp;
        }
        const elapsed = timestamp - start;

        // Incrementally reveal dots based on elapsed time
        visibleDots = Math.min(Math.floor(elapsed / delayBetweenDots) + 1, positions.length);

        const currentPositions = positions.slice(0, visibleDots).map((pos, index) => ({
          x: previousData[index]?.x + (pos.x - previousData[index]?.x || 0),
          y: previousData[index]?.y + (pos.y - previousData[index]?.y || 0)
        }));

        drawDotsAndArrows(currentPositions, visibleDots);

        if (visibleDots < positions.length) {
          requestAnimationFrame(animateDots);
        } else {
          setPreviousData(positions);
          setIsTransitioning(false);
        }
      };

      requestAnimationFrame(animateDots);
    }
  }, [data]);

  return (<article>
    <h2>Task Environment Space</h2>
    <canvas ref={canvasRef} style={{width: "300px", height: "230px", border: "0px solid black"}}/>
  </article>);
}

import Graph from "../components/graph";
import "./afterCognitive.css";

export default function AfterCognitive({before, after}) {
  return (<article className="afterCongitive">
    <h2>Cognitive Ability</h2>
    <h3>after</h3>
    <div className="congitiveBody">
      <div className="graphLabel">
        <div style={{width: "25%"}}>
          <p style={{backgroundColor: "#faedcd"}}>표준이하</p>
        </div>
        <div style={{width: "41%"}}>
          <p style={{backgroundColor: "#fefae0"}}>표준</p>
        </div>
        <div style={{width: "34%"}}>
          <p style={{backgroundColor: "#e9edc9"}}>표준이상</p>
        </div>
      </div>
      <div className="graphContainer">
        <button className="graphButton">전반적인 의사결정</button>
        <Graph value={before?.[0]} afterValue={after?.[0]} divisions={[25, 66]}/>
      </div>
      <div className="graphContainer">
        <button className="graphButton">메타인지</button>
        <Graph value={before?.[1]} afterValue={after?.[1]} divisions={[25, 66]}/>
      </div>
      <div className="graphContainer">
        <button className="graphButton">의사결정</button>
        <Graph value={before?.[2]} afterValue={after?.[2]} divisions={[25, 66]}/>
      </div>
    </div>
  </article>);
}

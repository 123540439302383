import "./beforeCognitive.css";
import Graph from "../components/graph";

export default function BeforeCognitive({data, clicked, setClicked}) {
  const onButtonClicked = (index) => {
    if (data === undefined) {
      return;
    }

    if (clicked === index) {
      setClicked(null);
    } else {
      setClicked(index);
    }
  };

  return (<article className="beforeCongitive">
    <h2>Cognitive Ability</h2>
    <h3>before</h3>
    <div className="congitiveBody">
      <div className="graphLabel">
        <div style={{width: "25%"}}>
          <p style={{backgroundColor: "#faedcd"}}>표준이하</p>
        </div>
        <div style={{width: "41%"}}>
          <p style={{backgroundColor: "#fefae0"}}>표준</p>
        </div>
        <div style={{width: "34%"}}>
          <p style={{backgroundColor: "#e9edc9"}}>표준이상</p>
        </div>
      </div>
      <div className="graphContainer">
        <button className={`graphButton ${clicked === 0 ? "activeGraph" : ""}`}
          onClick={() => onButtonClicked(0)}>전반적인 의사결정
        </button>
        <Graph value={data?.[0]} divisions={[25, 66]}/>
      </div>
      <div className="graphContainer">
        <button className={`graphButton ${clicked === 1 ? "activeGraph" : ""}`} onClick={() => onButtonClicked(1)}>메타인지
        </button>
        <Graph value={data?.[1]} divisions={[25, 66]}/>
      </div>
      <div className="graphContainer">
        <button className={`graphButton ${clicked === 2 ? "activeGraph" : ""}`} onClick={() => onButtonClicked(2)}>의사결정
        </button>
        <Graph value={data?.[2]} divisions={[25, 66]}/>
      </div>
    </div>
  </article>);
}

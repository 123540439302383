import React, {useState, useRef, useEffect} from "react";
import "./App.css";
import processMATFile from "./readMAT";
import BeforeCognitive from "./pages/beforeCognitive";
import AfterCognitive from "./pages/afterCognitive";
import ModelParameterSpace from "./pages/modelParameterSpace";
import TaskEnvironmentSpace from "./pages/taskEnvironmentSpace";

function App() {
  const [modifiedData, setModifiedData] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [name, setName] = useState("");
  const [afterCognitiveData, setAfterCognitiveData] = useState(null);
  const [modelParameterData, setModelParameterData] = useState(null);
  const [taskEnvironmentData, setTaskEnvironmentData] = useState(null);

  const fileInputRef = useRef(null); // useRef로 input 요소 참조

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    try {
      const {modifiedData, name} = await processMATFile(file);
      setModifiedData(modifiedData);
      setName(name);
    } catch (error) {
      console.error("Error processing MAT file:", error);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click(); // useRef를 통해 input 요소 클릭
  };

  useEffect(() => {
    setAfterCognitiveData(null);
    setModelParameterData(null);
    setTaskEnvironmentData(null);
    if (clicked !== null && modifiedData) {
      // AfterCognitive는 10초 후에 데이터 설정
      setTimeout(() => {
        setAfterCognitiveData({
          before: modifiedData?.[0][0], after: modifiedData?.[clicked][5]
        });
      }, 9000);

      // ModelParameterSpace는 3초 후에 데이터 설정
      setTimeout(() => {
        setModelParameterData(modifiedData?.[clicked].slice(1, 4));
      }, 100);

      // TaskEnvironmentSpace는 6초 후에 데이터 설정
      setTimeout(() => {
        setTaskEnvironmentData(modifiedData?.[clicked][4]);
      }, 3000);
    }
  }, [clicked, modifiedData]);

  return (<div className="App">
    <header className="header">
      <div className="fileNameBox" onClick={handleClick}>
        <p>Name: {name || " "}</p>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFileUpload}
        accept=".mat"
        style={{display: "none"}}
      />
    </header>
    <main className="body">
      <section className="left">
        <BeforeCognitive
          data={modifiedData?.[0][0]}
          clicked={clicked}
          setClicked={setClicked}
        />
        <AfterCognitive
          before={afterCognitiveData?.before || null}
          after={afterCognitiveData?.after || null}
        />
      </section>
      <section className="right">
        <ModelParameterSpace
          data={modelParameterData || null}
        />
        <TaskEnvironmentSpace
          data={taskEnvironmentData || null}
        />
      </section>
    </main>
  </div>);
}

export default App;
